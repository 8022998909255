import fetch from 'cross-fetch'
import 'url-search-params-polyfill'
import 'isomorphic-fetch'
import { logout, updateHasLogoutFailed } from './user-actions'

export const createPostRequest = (url, body) => async (dispatch, getState) => {
	const state = getState()
	const response = await fetch(url, {
		method: 'POST',
		credentials: 'same-origin',
		body: body
	})

	if (response.status == 401 && !state.user.hasLogoutFailed) {
		dispatch(logout())
		dispatch(updateHasLogoutFailed(true))
		return false
	}

	return response
}

export const createGetRequest = (url) => async (dispatch, getState) => {
	const state = getState()
	const response = await fetch(url, {
		method: 'GET',
		credentials: 'same-origin',
		mode: 'cors',
		header: 'Access-Control-Allow-Origin'		
	})

	if (response.status == 401 && !state.user.hasLogoutFailed) {
		dispatch(logout())
		dispatch(updateHasLogoutFailed(true))
		return false
	}

	return response
}



