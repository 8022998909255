import { createPostRequest, createGetRequest } from './fetch-actions'
import projectSettings from '../projectsettings'

export const getReport = (id) => async dispatch => {
	dispatch(updateIsReportLoading(true))

	const response = await dispatch(createGetRequest(projectSettings.url + '/api/conversations/' + id))
	const payload = await response.json()
	await dispatch(setReport(payload))

	if (response.status === 200) {dispatch(updateIsReportLoading(false))}
}

export const getConversations = () => async dispatch => {
	dispatch(updateAreConversationsLoading(true))

	const response = await dispatch(createGetRequest(projectSettings.url + '/api/conversations'))
	const payload = await response.json()
	dispatch(setConversations(payload))

	dispatch(updateAreConversationsLoading(false))
}

export const updateConversation = (values, id) => async dispatch => {

	dispatch(updateSubmitLoading(true))

	const formatDate = () => {
		const date = new Date(values.date)
		return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
	}

	const formData = new FormData()
	formData.append('line', values.line)
	formData.append('name', values.name)
	formData.append('date', formatDate(values.date.date))
	formData.append('moment_of_day', values.moment_of_day)
	formData.append('duration', values.duration)
	formData.append('spoken', values.spoken)
	formData.append('acquired', values.acquired)
	formData.append('age', values.age)
	formData.append('gender', values.gender)
	formData.append('living_situation', values.living_situation)
	formData.append('description', values.description)
	formData.append('make_call_request', values.make_call_request)
	formData.append('salutation', values.salutation)
	formData.append('email', values.email)
	formData.append('call_request_name', values.call_request_name)
	formData.append('phone_number', values.phone_number)
	formData.append('subject', values.subject)
	formData.append('subject_text', values.subject_text)
	
	formData.append('id', id)

	if (values.substances && Object.keys(values.substances).length > 0) {
		for (const [key, value] of Object.entries(values.substances)) {
			if (value) formData.append('substances[]', key.replaceAll('\'', ''))
		}
	}

	if (values.substance && Object.keys(values.substance).length > 0) {
		for (const [key, value] of Object.entries(values.substance)) {
			formData.append('substances[]', value)
		}
	}

	const payload = await dispatch(createPostRequest(`${projectSettings.url}/api/update-conversation`, formData))
	const response = await payload.json()

	if (response.status === 'success') {
		dispatch(updateSubmitLoading(false))
		await dispatch(setThankyou(true))
	}
}

export const createConversation = (values) => async dispatch => {

	dispatch(updateSubmitLoading(true))

	const formatDate = () => {
		const date = new Date(values.date)
		return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
	}
	
	const formData = new FormData()
	formData.append('line', values.line)
	formData.append('name', values.name)
	formData.append('date', formatDate(values.date.date))
	formData.append('moment_of_day', values.moment_of_day)
	formData.append('duration', values.duration)
	formData.append('age', values.age)
	formData.append('gender', values.gender)
	formData.append('spoken', values.spoken)
	formData.append('acquired', values.acquired)
	formData.append('living_situation', values.living_situation)
	formData.append('description', values.description)
	formData.append('make_call_request', values.make_call_request)
	formData.append('salutation', values.salutation)
	formData.append('email', values.email)
	formData.append('call_request_name', values.call_request_name)
	formData.append('phone_number', values.phone_number)
	formData.append('subject', values.subject)
	formData.append('subject_text', values.subject_text)

	if (values.substances && Object.keys(values.substances).length > 0) {
		for (const [key, value] of Object.entries(values.substances)) {
			if (value) formData.append('substances[]', key.replaceAll('\'', ''))
		}
	}
	
	const payload = await dispatch(createPostRequest(`${projectSettings.url}/api/save-conversation`, formData))
	const response = await payload.json()
	
	if (response.status === 'success') {
		dispatch(updateSubmitLoading(false))
		await dispatch(setThankyou(true))
	}
}

export const getSubstances = () => async dispatch => {
	dispatch(updateAreSubstancesLoading(true))

	const response = await dispatch(createGetRequest(projectSettings.url + '/api/get-substance-options'))
	const payload = await response.json()
	await dispatch(updateSubstance(payload))

	if (response.status === 200) {dispatch(updateAreSubstancesLoading(false))}
}
export const getSpoken = () => async dispatch => {
	
	const response = await dispatch(createGetRequest(projectSettings.url + '/api/get-spoken-options'))
	const payload = await response.json()
	await dispatch(updateSpoken(payload))
}

export const getAcquired = () => async dispatch => {
	const response = await dispatch(createGetRequest(projectSettings.url + '/api/get-acquired-options'))
	const payload = await response.json()
	await dispatch(updateAcquired(payload))
}

const setConversations = value => ({
	type: 'UPDATE_CONVERSATIONS',
	value
})

export const setThankyou = (value) => ({
	type: 'SET_THANKYOU',
	value
})

const updateSubstance = value => ({
	type: 'UPDATE_SUBSTANCES',
	value
})
const updateSpoken = value => ({
	type: 'UPDATE_SPOKEN',
	value
})
const updateAcquired = value => ({
	type: 'UPDATE_ACQUIRED',
	value
})

export const updateAreSubstancesLoading = value => ({
	type: 'UPDATE_ARE_SUBSTANCES_LOADING',
	value
})

export const updateAreConversationsLoading = value => ({
	type: 'UPDATE_ARE_CONVERSATIONS_LOADING',
	value
})

export const updateIsReportLoading = value => ({
	type: 'UPDATE_IS_REPORT_LOADING',
	value
})
export const updateSubmitLoading = value => ({
	type: 'UPDATE_SUBMIT_LOADING',
	value
})

export const setReport = (value ) => ({
	type: 'SET_REPORT',
	value
})