import React from 'react'

const Headershape = () => (
	<div className="headershape" style={{
		backgroundImage: 'url(/img/headershape.svg)'
	}}>
		<h3>DementieLijn</h3>
	</div>
)

export default Headershape
