import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadProfile } from '../actions/user-actions'

const Whois = () => {
	const dispatch = useDispatch()

	const profile = useSelector(state => state.user.profile)

	useEffect(() => {
		dispatch(loadProfile())
	}, [])
	
	return (
		<div className="whois">
			<h5>Ingelogd als<br />
				{profile &&
					<Fragment>
						<strong>{profile.user}</strong>	
					</Fragment>
				}
			</h5>
		</div>
	)
}

export default Whois