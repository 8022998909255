const EMAIL_REGEX = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$) /i
const ALPHANUMERIC_REGEX = /[^a-zA-Z. ]/i

export const composeValidators = (...validators) => value =>
	validators.reduce((error, validator) => error || validator(value), undefined)

export const required = value => 
	value || typeof value === 'number' 
		? undefined
		: 'Dit veld is verplicht'

export const atLeastOne = (allValues, values) =>
	values?.substances && Object.values(values?.substances).filter(substance => substance).length > 0
		? undefined
		: 'Verplicht'

export const maxLength = max => value =>
	value && value.length > max ? `Moet maximaal ${max} characters bevatten` : undefined

export const minLength = min => value =>
	value && value.length < min ? ` minimaal ${min} cijfers` : undefined


export const maxLength64 = maxLength(64)

export const maxLength128 = maxLength(128)
	
export const minLength10 = minLength(10)

export const email = value =>
	value && !EMAIL_REGEX.test(value)
		? 'Ongeldig e-mailadres'
		: undefined
        
export const alphaNumeric = value =>
	value && ALPHANUMERIC_REGEX.test(value)
		? 'Alleen alfanumerieke characters'
		: undefined

export const number = value =>
	value && isNaN(Number(value)) ? 'Moet een getal zijn' : undefined
		
export const test = (allValues, values) =>
	console.log(values, allValues)


export const testing = (...validators) => value =>
	validators.reduce((error, validator) => error || validator(value), undefined)