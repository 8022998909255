import React, { useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { attemptLogin } from '../actions/user-actions'
import Logo from '../components/Logo'
import FormOneStop from '../components/FormOneStop'
import Homeshape from '../components/Homeshape'

const Home = () => {
	const params = useParams()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	
	const isUserAuthenticated = useSelector(state => state.user.isUserAuthenticated)

	useEffect(() => {
		if (params.hash) dispatch(attemptLogin(params.hash))
	}, [])
	
	useEffect(() => {
		if (isUserAuthenticated) navigate('/overzicht')
	}, [isUserAuthenticated])

	return (
		<React.Fragment>
			<Homeshape />
			<main className="main_login">
				<Logo/>

				<section className="login">
					<article>
						<h2>Gespreksverslag DementieLijn</h2>

						<FormOneStop />
					</article>
				</section>
			</main>
		</React.Fragment>
	)
}

export default Home
