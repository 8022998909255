import React from 'react'
import './assets/sass/app.scss'
import { Provider } from 'react-redux'
import Store from './store'
import { createRoot } from 'react-dom/client'
import MainRouter from './main-router'

const container = document.getElementById('root')
const root = createRoot(container)

root.render(	
	<Provider store={Store}>
		<MainRouter/>
	</Provider>
)