import React, { useState, Fragment } from 'react'
import { useDispatch } from 'react-redux'
import { Form, Field } from 'react-final-form'
import { EmailInput } from './Inputs'
import { getOneStop } from '../actions/user-actions'
import { composeValidators, required, email } from '../helpers/validate'

const FormOneStop = () => {
	const dispatch = useDispatch()
	const [hashSent, setHashSent] = useState()

	const onSubmit = async values => {
		const trimValue = values.email.split(' ').join('')
		setHashSent(false)

		const callSucceeded = await dispatch(getOneStop(trimValue))
		if (callSucceeded) setHashSent(true)
	}

	return (
		<Fragment>
			<Form
				onSubmit={onSubmit}
				render={({handleSubmit, submitting, form}) => (
					<form onSubmit={event => {
						const promise = handleSubmit(event)
						promise && promise.then((value) => {
							if (value === true) {
								form.reset()
								setHashSent(true)
							}
						})

						return promise
					}}>
						{!hashSent &&
                            <Fragment>
                        		<fieldset>
                            		<Field
                            			name="email"
                            			label="E-mailadres"
                            			id="email"
                            			validate={composeValidators(required, email)}
                            			component={EmailInput}
                            		/>
                            	</fieldset>
                            	<fieldset className="actions">
                            		<button disabled={submitting} className="btn">Link opvragen</button>
                            	</fieldset>
                            	<fieldset className="login-notice">
                            		<p><strong>Let op:</strong> Je kunt het Gespreksverslag DementieLijn pas gebruiken als we je hebben aangemeld in het systeem. Nog niet aangemeld?
									Stuur dan een mail naar <a href="mailto:dementielijn@alzheimer-nederland.nl">dementielijn@alzheimer-nederland.nl</a>.</p>
                            	</fieldset>
                            </Fragment>
						}
						{hashSent &&
							<fieldset className="login-notice">
								<p>Er is een e-mail gestuurd naar jouw mailadres (@alzheimervrijwilligers.nl).
                                    Check ook even je spam-box, mocht je de e-mail niet binnen 5 minuten hebben ontvangen.</p>
							</fieldset>
						}
					</form>
				)}
			/>

		</Fragment>
	)
}

export default FormOneStop
