import React from 'react'
import { useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { logout } from '../actions/user-actions'
import WhoIs from './Whois'

const Navigation = () => {
	const dispatch = useDispatch()

	const submitLogout = () => dispatch(logout())

	return (
		<nav>
			<NavLink to={'/overzicht'} >Overzicht</NavLink>
			<NavLink to={'/gesprekverslag'} >Nieuw Gesprekverslag</NavLink>
			<WhoIs />
			<NavLink to={'/'} onClick={(e) => { submitLogout(); e.preventDefault()}}>Uitloggen</NavLink>
		</nav>
	)
}

export default Navigation
