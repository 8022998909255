import { createPostRequest, createGetRequest } from './fetch-actions'
import projectSettings from '../projectsettings'

export const getOneStop = email => async dispatch => {
	const data = new FormData()
	data.append('email', email)

	const payload = await dispatch(createPostRequest(`${projectSettings.url}/check-user`, data))
	const response = await payload.json()

	if (response.status === 'success') return true
	return false
}

export const attemptLogin = hash => async dispatch => {
	const payload = await dispatch(createGetRequest(`${projectSettings.url}/api/check/onestop/${hash}`))
	const response = await payload.json()

	if (response.status === 'success') {
		dispatch(updateIsUserAuthenticated(true))
		localStorage.setItem('isAuthenticated', true)
	} else {
		dispatch(updateIsUserAuthenticated(false))
		dispatch(resetUserState())
	}
}

export const attemptLocalstorageLogin = () => async dispatch => {
	const isAuthenticated = await localStorage.getItem('isAuthenticated')

	dispatch(updateIsUserAuthenticated(isAuthenticated))
}

export const logout = () => async dispatch => {
	await dispatch(createGetRequest(`${projectSettings.url}/logout`))

	localStorage.removeItem('isAuthenticated')
	dispatch(resetUserState())
}

export const resetUserState = () => ({
	type: 'RESET_USER_STATE'
})

const updateIsUserAuthenticated = value => ({
	type: 'UPDATE_IS_USER_AUTHENTICATED',
	value
})

export const updateHasLogoutFailed = value => ({
	type: 'UPDATE_HAS_LOGOUT_FAILED',
	value
})


export const loadProfile = () => async dispatch => {
	const payload = await dispatch(createGetRequest(`${projectSettings.url}/api/get-user`))
	const response = await payload.json()

	await dispatch(updateProfile(response))

}

const updateProfile = value => ({
	type: 'UPDATE_PROFILE',
	value
})
