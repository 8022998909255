import { thunk } from 'redux-thunk'
import { legacy_createStore as createStore, applyMiddleware, compose, combineReducers } from 'redux'
import global from './reducers/global-reducer'
import user from './reducers/user-reducer'
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const middleware = applyMiddleware(thunk)
// const persistedState = localStorage.getItem('reduxState') ? JSON.parse(localStorage.getItem('reduxState')) : {}

const rootReducer = combineReducers({
	global,
	user
})

let store = createStore(
	rootReducer,
	// persistedState,
	composeEnhancers(middleware)
)

// store.subscribe(()=>{
// 	localStorage.setItem('reduxState', JSON.stringify(store.getState()))
// })

export default store
