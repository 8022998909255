import React from 'react'
import { NavLink } from 'react-router-dom'

const Logo = () => (
	<div className="logo">
		<NavLink to={'/overzicht'}>
			<img src="/img/logo.svg" alt="Alzheimer Nederland" />
		</NavLink>
	</div>
)



export default Logo
