import React, { Fragment ,useState} from 'react'
import { CSSTransition } from 'react-transition-group'

const InfoCallreport = () => {
	const [isInfoCallreportOpen, SetInfoCallreportOpen] = useState()
	return (
		<Fragment>
			<div onClick={() => SetInfoCallreportOpen(!isInfoCallreportOpen)} className='info__button'>i</div>

			<CSSTransition
				classNames="info__button"
				timeout={300}
				unmountOnExit
				in={isInfoCallreportOpen }
			>

				<div className="info-callreport">
					<div className="info-callreport__container">
						<img className="close-icon" src="/img/close.svg" alt="close" onClick={() => SetInfoCallreportOpen(false)} />
						<h3>Opmerkingen bij specifieke vragen:</h3>
						<ul>
							<li><p>Voor informatie, tips en ervaringsverhalen: verwijs naar <a href="https://www.dementie.nl" target="_blank" rel="noopener noreferrer">dementie.nl</a></p></li>
							<li><p>Voor vragen over donateurs(administratie), collecte en andere kantoorzaken:
								<br /><b>- verwijs naar Publieksservice:</b> 033-303 2502 of <a href="mailto:info@alzheimer-nederland.nl">info@alzheimer-nederland.nl</a>
								<br /> <b>- IBAN Alzheimer Nederland: NL13 INGB 0000002502</b></p></li>
							<li><p>
Voor bestellingen van o.a.: informatiemateriaal en cadeautjes met betekenis: vraag of de beller toegang tot internet heeft en wijs op de webwinkel: <a href="https://www.alzheimer-nederland.nl/webwinkel" target="_blank" rel="noopener noreferrer">alzheimer-nederland.nl/webwinkel</a> Geen internet: verwijzen naar Publieksservice.</p></li>
							<li><p>Advies van een expert nodig? Graag contactgegevens invullen voor een terugbel- of mailverzoek </p></li>

						</ul>
					</div>
				</div>
			</CSSTransition>
		</Fragment>
	)
}


export default InfoCallreport
