import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'

const PrivateRoutes = () =>{
	const isUserAuthenticated = useSelector(state => state.user.isUserAuthenticated)

	return( isUserAuthenticated ? <Outlet /> : <Navigate to="/"  replace />)
}

export default PrivateRoutes
