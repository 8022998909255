import React from 'react'

const Homeshape = () => (
	<div className="homeshape">
	</div>
)



export default Homeshape
