import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { attemptLocalstorageLogin } from './actions/user-actions'
import PrivateRoutes from './components/PrivateRoutes'
import Loginpage from './pages/login-page'
import Overview from './pages/overview'
import CallForm from './pages/callreport'
import ThankYou from './pages/thank_you'


const MainRouter = () => {
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(attemptLocalstorageLogin())
	}, [])

	const router = createBrowserRouter(
		[
			{
				path: '/',
				element: <Loginpage />,
			},
			{
				path: '/login/:hash',
				element: <Loginpage />,
			},
			{
				element: <PrivateRoutes />,
				children: [
					{
						path: '/overzicht',
						element: <Overview />,
					},
					{
						path: '/gesprekverslag',
						element: <CallForm />,
					},
					{
						path: '/gesprekverslag/:id',
						element: <CallForm />,
					},
					{
						path: '/bedankt',
						element: <ThankYou />,
					},
				],
			},
		], { basename: '/' },
	)

	return <RouterProvider router={router} />
}


export default MainRouter
