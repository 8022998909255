import React, { useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSubstances, getReport, setReport, getSpoken, getAcquired } from '../actions/global-actions'
import { loadProfile } from '../actions/user-actions'
import FormCallReport from '../components/FormCallReport'
import Logo from '../components/Logo'
import Navigation from '../components/Navigation'
import Headershape from '../components/Headershape'
import InfoCallreport from '../components/info-callreport'
import { useParams, Navigate } from 'react-router-dom'

const CallReport = () => {
	const { id } = useParams()
	const dispatch = useDispatch()

	const areSubstancesLoading = useSelector(state => state.global.areSubstancesLoading)
	const report = useSelector(state => state.global.report)
	const thankyouMessage = useSelector(state => state.global.thankyouMessage)
	const isReportLoading = useSelector(state => state.global.isReportLoading)
	const isSubmitLoading = useSelector(state => state.global.isSubmitLoading)

	useEffect(() => {
		if (!id) dispatch(loadProfile())
		if (id) dispatch(getReport(id))
		
		dispatch(getSubstances())
		dispatch(getSpoken())
		dispatch(getAcquired())

		return () => dispatch(setReport([]))
	}, [id])

	if (thankyouMessage) {
		return (
			<Navigate replace to={'/bedankt'} />
		)
	}

	return (
		<React.Fragment>
			<Headershape />
			<main>
				<section className="page callreport-form-page">
					<aside>
						<Logo />

						<Navigation active="start" />
					</aside>

					<article>
						<div className="row">
							<InfoCallreport />

	
							<div className="callreport__title">
								<h1>Gesprekverslag 
									{report.id && 
										<Fragment>
											: {report.id }
										</Fragment>
									}
								</h1>
							</div>
							
							{!areSubstancesLoading && ((id && report.id) || !id) && 
								<FormCallReport id={id} initialValues={report} />
							}
							{(areSubstancesLoading || isReportLoading) &&
								<div className="loader-container">
									<div className="loader"></div>
								</div>
							}
							{(isSubmitLoading) &&
							<div className="loader-container">
								<div className="loader"></div>
							</div>
							}
						</div>
					</article>
				</section>
			</main>
		</React.Fragment>
	)
}

export default CallReport
