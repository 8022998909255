import React ,{Fragment, useEffect} from 'react'
import { useDispatch } from 'react-redux'
import Headershape from '../components/Headershape'
import Logo from '../components/Logo'
import Navigation from '../components/Navigation'
import {useNavigate } from 'react-router-dom'
import { setThankyou } from '../actions/global-actions'

const ThankMessage = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()

	useEffect(() => {
		setTimeout (()=>{
			navigate('/overzicht')
		},4000)
		return () => {
			dispatch(setThankyou(false))
		}
	}, [])

	return (
		<Fragment>
			<Headershape/>
			<main>
				<section className="page">
					<aside>
						<Logo />
						<Navigation/>
					</aside>
					<article>
						<div className="thank-you">
							<h1>Bedankt voor het invullen van dit gespreksverslag.</h1>
						</div>
					</article>
				</section>
			</main>
		</Fragment>
	)
}

export default ThankMessage
