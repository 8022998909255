import React, { Fragment, useState, useEffect } from 'react'
import { Form, Field } from 'react-final-form'
import { EmailInput, TextInput, DateInput, NumberInput, TextAreaInput, SelectInput, CheckboxInput, ContactInput } from '../components/Inputs'
import { composeValidators, required, email, number, minLength10, atLeastOne } from '../helpers/validate'
import { useDispatch, useSelector } from 'react-redux'
import { createConversation, updateConversation } from '../actions/global-actions'
import { Navigate } from 'react-router-dom'

const FormCallReport = ({ initialValues, id}) => {
	const dispatch = useDispatch()
	const [isUpdateForm, setIsUpdateForm] = useState()

	const spoken = useSelector(state => state.global.spoken)
	const acquired = useSelector(state => state.global.acquired)
	const substances = useSelector(state => state.global.substances)
	const thankyou = useSelector(state => state.global.thankyou)
	const profile = useSelector(state => state.user.profile)

	useEffect(() => {
		if (id) setIsUpdateForm(true)
	}, [])

	const onSubmit = async values => {
		if (!id) return dispatch(createConversation(values))
		if (id) return dispatch(updateConversation(values, id))
	}

	if (thankyou) {
		return (
			<Navigate replace to={'/bedankt'} />
		)
	}

	let spokenList = []
	spoken && spoken.map((item, index) => {
		spokenList.push({
			key: index,
			id: item.id,
			value: item.id,
			text: item.spoken
		})
	})

	let acquiredList = []
	acquired && acquired.map((item, index) => {
		acquiredList.push({
			key: index,
			id: item.id,
			value: item.id,
			text: item.acquired
		})
	})
	return (
		<Form
			onSubmit={values => onSubmit(values)}
			initialValues={Object.keys(initialValues).length > 0
				? {
					...initialValues,
					date: new Date(initialValues.date.date),
				}
				: {
					date: new Date(),
					name: profile.user,
					spoken: '1',
					acquired: '1',
					line: '-',
					gender: '-',
					moment_of_day: 'Ochtend',
					age: '-',
					salutation: '-',
					subject: 'QUE015',
					subject_text: profile.user === 'Anoniem' ? 'Dit formulier is via de DementieLijn ingevuld door een anonieme vrijwilliger \n\n' : `Dit formulier is via de DementieLijn ingevuld door vrijwilliger ${profile.user} \n\n`
				}
			}
			render={({ handleSubmit, submitting, values, submitFailed, errors }) => (
				<form className="callreport-form" onSubmit={e => e.preventDefault()}>
					<h3>Beantwoord door vrijwilliger</h3>
					<fieldset className="volunteer">
						<div className="volunteer__containter">
							<Field
								name="line"
								label="Lijn:"
								id="Line"
								component={SelectInput}
								options={[
									{value:'', text: '-' },
									{value:1, text: '1' },
									{value:2, text: '2' },
									{value:'N.a.v. terugbelverzoek', text: 'N.a.v. terugbelverzoek' },
								]}
							/>
							<Field
								name="name"
								label="Naam:"
								id="name"
								validate={composeValidators(required)}
								component={TextInput}

							/>
						</div>
					</fieldset>
					<hr/>
					<fieldset className="time-of-day">
						<div className="time-of-day__containter">
							<Field
								name="date"
								label="Datum"
								id="date"
								component={DateInput}
								validate={required}

							/>
							<Field
								name="moment_of_day"
								label="Dagdeel"
								id="moment_of_day"
								component={SelectInput}
								options={[
									{ value:'Ochtend', text: 'Ochtend' },
									{ value:'Middag', text: 'Middag' },
									{ value:'Avond', text: 'Avond' },
								]}
							/>
							<Field
								name="duration"
								label="* Gesprekduur (Aantal Minuten)"
								id="duration"
								component={NumberInput}
								validate={required}
							/>
						</div>
					</fieldset>
					<hr/>
					<h3>Beller</h3>
					<fieldset className="caller">
						<div className="caller__containter">
							{spoken && spoken.length > 0 &&
							<Field
								name="spoken"
								label="Gesproken met:"
								id="spoken"
								component={SelectInput}
								options={spokenList}

							/>
							}
							{acquired && acquired.length > 0 &&
								<Field
									name="acquired"
									label="Nummer DementieLijn verkregen via:"
									id="acquired"
									component={SelectInput}
									options={acquiredList}
								/>
							}
						</div>
					</fieldset>
					<hr/>
					<h3>Over de persoon met dementie:</h3>
					<fieldset className="about-person">
						<div className="about-person__containter">
							<Field
								name="age"
								label="Leeftijd"
								id="age"
								component={SelectInput}
								options={[
									{
										value: '-',
										text: '-'
									},
									{
										value: '< 65',
										text: '< 65'
									},
									{
										value: '66 - 74',
										text: '66 - 74'
									},
									{
										value: '75 >',
										text: '75 >'
									},
								]}
							/>

							<Field
								name="gender"
								label="Geslacht"
								id="gender"
								component={SelectInput}
								options={[
									{value:'O', text: '-' },
									{value:'M', text: 'Man' },
									{value:'V', text: 'Vrouw' },
									{value:'X', text: 'X' },
								]}
							/>

							<Field
								name="living_situation"
								label="Situatie"
								id="living_situation"
								component={SelectInput}
								options={[
									{ value:'-', text:'-' },
									{ value:'n-v-t', text:'N.v.t.' },
									{ value:'niet-pluis fase', text: 'Niet-pluis fase' },
									{ value:'dementie zonder diagnose', text: 'Dementie zonder diagnose' },
									{ value:'thuiswonend met dementie', text: 'Thuiswonend met dementie' },
									{ value:'verpleeghuis fase', text: 'Verpleeghuis fase' },
									{ value:'(naderend) levenseinde', text: '(naderend) Levenseinde' }
								]}
							/>
						</div>
					</fieldset>
					<hr/>
					<h3>
						* Inhoud van het gesprek<i>Meerdere antwoorden mogelijk</i>
						<p className="error">{errors.substances && submitFailed && Object.values(errors.substances)[0]}</p>
					</h3>
					<fieldset className="substance">

						<div className="substance__containter" >
							{substances.map((item, index) => {
								if(item.active === true ) {
									return (
										<Field
											key={index}
											component={CheckboxInput}
											name={`substances['${item.id}']`}
											id={`substances['${item.id}']`}
											label={item.substance}
											initialChecked={initialValues.substance?.includes(item.id)}
											type="checkbox"
											validate={atLeastOne}
										/>
									)} if(initialValues.substance?.includes(item.id) === (item.active === false))  {
									return (
										<Field
											key={index}
											component={CheckboxInput}
											name={`substances['${item.id}']`}
											id={`substances['${item.id}']`}
											label={item.substance}
											initialChecked={initialValues.substance?.includes(item.id)}
											type="checkbox"
											validate={atLeastOne}
										/>
									)
								}
							})}
						</div>
					</fieldset>
					<hr/>
					<h3>Het gesprek:</h3>
					<fieldset className="description">
						<div className="description__containter">
							<Field
								name="description"
								label="* Korte weergave van het gesprek:"
								id="description"
								component={TextAreaInput}
								validate={required}
							/>
						</div>
					</fieldset>

					{isUpdateForm && initialValues.make_call_request === '1' &&
						<Fragment>
							<hr />
							<h3>Er is een terugbelverzoek ingediend</h3>
						</Fragment>
					}

					{!isUpdateForm &&
						<Fragment>
							<hr/>
							{profile.user &&
								<h3>Vraag aan expert<br/>
									<i>Alleen met toestemming invullen als de beller wil dat er contact opgenomen wordt door een expert.<br/>
									De expert ontvangt alleen de informatie onder `vraag aan expert`.</i>
								</h3>
							}
							{!profile.user &&
								<h3>Vraag aan expert<br/>
									<i>Alleen met toestemming invullen als de beller wil dat er contact opgenomen wordt door een expert.<br/>
									De expert ontvangt alleen de informatie onder `vraag aan expert`.</i>
								</h3>
							}
							{!initialValues.make_call_request === false && !initialValues.make_call_request  &&
								<h3>Vraag aan expert<br/>
									<i>Alleen met toestemming invullen als de beller wil dat er contact opgenomen wordt door een expert.<br/>
									De expert ontvangt alleen de informatie onder `vraag aan expert`.</i>
								</h3>
							}
							<fieldset className="callback">
								<div className="callback__containter" >
									{/* <div className="callback__containter" onChange={() => SetCallbackOpen(!isCallbackOpen)} > */}
									<Field
										component={CheckboxInput}
										name='make_call_request'
										id='make_call_request'
										label='Vraag aan expert indienen'
										type="checkbox"
									/>
								</div>
							</fieldset>

							{values.make_call_request &&
								<Fragment>
									<fieldset className="contact-details">
										<div className="contact-details__containter">
											<Field
												name="salutation"
												label="Aanspreektitel"
												id="salutation"
												component={SelectInput}
												options={[
													{id: 0, value:0, text: '-' },
													{id: 1, value:1, text: 'Dhr.' },
													{id: 2, value:2, text: 'Mevr.' },
												]}
											/>
											<Field
												name="call_request_name"
												label="Naam"
												id="call_request_name"
												component={TextInput}
												validate={required}
											/>

											<Field
												name="email"
												label="E-mail"
												id="email"
												validate={composeValidators(required, email)}
												component={EmailInput}
											/>

											<Field
												name="phone_number"
												label="Telefoon"
												validate={composeValidators(required, number, minLength10)}
												id="phone_number"
												component={TextInput}
											/>
										</div>
									</fieldset>

									<h3>Deze persoon heeft een vraag voor:</h3>
									<fieldset className="subject-details">
										<div className="subject-details__containter">
											<Field
												type="radio"
												name="subject"
												component={ContactInput}
											/>
										</div>
									</fieldset>

									<fieldset className="subject-details">
										<div className="subject-details__containter">
											<Field
												name="subject_text"
												label="Omschrijf hier de vraag en/of de behoefte van de beller"
												id="subject_text"
												validate={required}
												component={TextAreaInput}
											/>
										</div>
									</fieldset>
								</Fragment>
							}
						</Fragment>
					}

					<hr className="no-margin" />

					<fieldset className="actions margin-top">
						<button onClick={() => handleSubmit()} disabled={submitting} className="btn">Versturen</button>
						{submitFailed && <p className="error">* Niet alle verplichte velden zijn correct ingevuld</p>}
					</fieldset>
				</form>
			)}
		/>
	)
}

export default FormCallReport
