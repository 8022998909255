const initialState = {
	isUserAuthenticated: false,
	hasLogoutFailed: false,
	profile: {},
}

const user = (state = initialState, action) => {
	switch(action.type) {
	case 'RESET_USER_STATE':
		return initialState
	case 'UPDATE_IS_USER_AUTHENTICATED':
		return {
			...state,
			isUserAuthenticated: action.value
		}
	case 'UPDATE_HAS_LOGOUT_FAILED':
		return {
			...state,
			hasLogoutFailed: action.value
		}
	case 'UPDATE_PROFILE':
		return {
			...state,
			profile: action.value
		}
	default:
		return state
	}
}

export default user
